
import Vue from 'vue';

import CreateKeyResponsee from 'Entities/apiManagement/CreateKeyResponse';
import KeyResponse from 'Entities/apiManagement/KeyResponse';
import { deleteApiKey } from 'Store/v2/ApiManagment';
import { IDeleteKeyPayload } from 'Lib/entities/apiManagement/DeleteKeyPayload';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import Button from 'Control/Button.vue';
import Icon from 'UI/Icon.vue';
import Checkbox from 'Control/Checkbox.vue';
import Guid from 'UI/Guid.vue';

interface Data {
    saved: boolean;
}
interface Methods {
    copyPublic: () => void;
    copySecret: () => void;
    deleteKey: () => void;
    onComplete: () => void;
    truncateKey: (data: string) => string;
}
interface Computed {
    apiKey: KeyResponse | CreateKeyResponsee;
    isNew: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    name: 'ManageApiKeys',
    components: {
        Button,
        Icon,
        Checkbox,
        Guid,
    },
    data() {
        return {
            saved: false,
        };
    },
    computed: {
        isNew() {
            const { newKey } = this.$router.currentRoute.query as { newKey?: string, apiKey?: string};
            return !!newKey;
        },
        apiKey() {
            const { newKey, apiKey } = this.$router.currentRoute.query as { newKey?: string, apiKey?: string};
            if (newKey) {
                return this.$store.state.ApiManagment.currentKey!;
            }
            return this.$store.state.ApiManagment.apiKeys.find((key) => key.apiKey === apiKey)!;
        },
    },
    methods: {
        async copyPublic() {
            await copyTextWithPermissions(this.apiKey.apiKey, this.$store.dispatch, 'API key');
        },
        async copySecret() {
            await copyTextWithPermissions((this.apiKey as CreateKeyResponsee).secret, this.$store.dispatch, 'Secret');
        },
        deleteKey() {
            const req: IDeleteKeyPayload = {
                apiKey: this.apiKey.apiKey,
                totp: '',
            };
            this.$store.dispatch(deleteApiKey(req));
        },
        onComplete() {
            this.$router.replace('/profile/manageApiKeys').catch(() => { /* navigation error */ });
        },
        truncateKey(key: string) {
            if (key.length < 9) {
                return key;
            }
            return `${key.slice(0, 4)}...${key.slice(-4)}`;
        },
    },
});
